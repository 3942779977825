import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  TonConnectButton,
  useTonConnectUI,
  useTonWallet,
} from '@tonconnect/ui-react';
import './App.css';

const transaction = {
  validUntil: Math.floor(Date.now() / 1000) + 60, // Valid for 60 seconds
  messages: [
    {
      address: 'EQBvW8Z5huBkMJYdnfAEM5JqTNkuWX3diqYENkWsIL0XggGG', // Example testnet address
      amount: '10000000', // 0.01 TON
    },
  ],
};

function App() {
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const tg = window.Telegram?.WebApp;
  const [balance, setBalance] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [transactionResponse, setTransactionResponse] = useState(null);
  const wsRef = useRef(null);
  const pollIntervalRef = useRef(null);

  const fetchBalance = useCallback(async () => {
    if (wallet?.account?.address) {
      try {
        const response = await fetch(
          `https://toncenter.com/api/v2/getAddressBalance?address=${wallet.account.address}`
        );
        const data = await response.json();
        if (data.ok) {
          setBalance(parseInt(data.result) / 1e9); // Convert from nanoTON to TON
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    }
  }, [wallet]);

  const setupWebSocket = useCallback(() => {
    if (wallet?.account?.address && !wsRef.current) {
      wsRef.current = new WebSocket('wss://toncenter.com/ws/api');
      
      wsRef.current.onopen = () => {
        console.log('WebSocket connected');
        wsRef.current.send(JSON.stringify({
          '@type': 'subscribe',
          'address': wallet.account.address,
          'event': 'state_changed'
        }));
      };
      
      wsRef.current.onmessage = async (event) => {
        const data = JSON.parse(event.data);
        if (data['@type'] === 'state_changed') {
          console.log('Account state changed, updating balance');
          await fetchBalance();
        }
      };
      
      wsRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        fallbackToPolling();
      };
      
      wsRef.current.onclose = () => {
        console.log('WebSocket disconnected');
        wsRef.current = null;
        fallbackToPolling();
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet, fetchBalance]);

  const fallbackToPolling = useCallback(() => {
    if (!pollIntervalRef.current) {
      console.log('Falling back to polling');
      pollIntervalRef.current = setInterval(fetchBalance, 10000); // Poll every 10 seconds
    }
  }, [fetchBalance]);

  useEffect(() => {
    if (wallet) {
      fetchBalance();
      setupWebSocket();
    }
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    };
  }, [wallet, fetchBalance, setupWebSocket]);

  const sendTestTransaction = async () => {
    if (!tonConnectUI) return;
    setTransactionStatus('Sending transaction...');

    try {
      const result = await tonConnectUI.sendTransaction(transaction);
      console.log('Transaction sent:', result);
      setTransactionStatus('Transaction sent successfully!');
      setTransactionResponse(result);
      
      // Manually trigger a balance update
      await fetchBalance();
      
      // If WebSocket is not connected, start polling
      if (!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN) {
        fallbackToPolling();
      }
    } catch (error) {
      console.error('Error sending transaction:', error);
      setTransactionStatus('Error sending transaction: ' + error.message);
    }
  };

  useEffect(() => {
    // Initialize Telegram Mini App
    const tg = window.Telegram?.WebApp;

    if (tg) {
      tg.ready();
      tg.expand();
    }
  }, []);

  return (
    <div className="app">
      <h1 className="app__title">TON Connect Telegram Mini App</h1>
      <TonConnectButton />
      {wallet && (
        <div className="app__content">
          <p>Balance: {balance !== null ? `${balance} TON` : 'Loading...'}</p>
          <div className="app__transaction-info">
            <pre className="app__transaction-text">{`root: ${JSON.stringify(
              transaction,
              null,
              2
            )}`}</pre>
          </div>
          <button onClick={sendTestTransaction}>Send Test Transaction</button>
          {transactionStatus && <p>{transactionStatus}</p>}
          <div className="app__transaction-info">
            <pre className="app__transaction-text">
              {`response: ${
                transactionResponse
                  ? JSON.stringify(transactionResponse, null, 2)
                  : '{}'
              }`}
            </pre>
          </div>
        </div>
      )}
      {tg && <button onClick={() => tg.close()}>Close Mini App</button>}
    </div>
  );
}

export default App;
